import axios from "axios";
const request = axios.create({
  baseURL: "/api/", // api的base_url  // http://39.105.101.79:8080   or   /api/
  timeout: 15 * 60 * 1000, // 请求超时时间 1分钟
});
request.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
// request拦截器
request.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// respone拦截器
request.interceptors.response.use(
  (res) => {
    //获取状态码
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default request;
