import request from "@/request";

// 获取新闻列表
export const getNewsList = (per_page, page) => {
  return request({
    url: `/wp-json/wp/v2/posts?_embed=wp:featuredmedia&filter[cat]=1&per_page=${per_page}&page=${page}`,
    method: "get",
  });
};
// 获取新闻详情
export const getNewsDetail = (postId) => {
  return request({
    url: `/wp-json/wp/v2/posts/${postId}`,
    method: "get",
  });
};
// 更新阅读量
export const updateNewsViews = (postId) => {
  return request({
    url: `/wp-json/custom/v1/update-views/${postId}`,
    method: "post",
  });
};
// 获取临近的文章
export const adjacentPosts = (postId) => {
  return request({
    url: `/wp-json/wp/v2/posts/${postId}?_fields=adjacent_posts`,
    method: "get",
  });
};
